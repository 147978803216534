var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mv-2 mb-6 rpt-download", attrs: { id: "hidden-bill" } },
    [
      _c("div", [
        _c(
          "div",
          { staticClass: "content-section", attrs: { id: "bill-detail" } },
          [
            _c("div", { staticClass: "md-layout rpt-location" }, [
              _c("p", [_c("strong", [_vm._v(_vm._s(_vm.name))])]),
              _c("p", [
                _vm._v(
                  _vm._s(_vm.address1) +
                    ", " +
                    _vm._s(_vm.address2) +
                    ", " +
                    _vm._s(_vm.address3)
                )
              ])
            ]),
            _c("div", { staticClass: "md-layout rpt-logo" }, [
              _c("img", { attrs: { src: _vm.logo } })
            ]),
            _c("div", { staticClass: "rpt-main" }, [
              _c("div", { staticClass: "md-layout flex-display" }, [
                _c("div", { staticClass: "md-layout-item" }, [
                  _c("div", { staticClass: "bill-sub-value text-right" }, [
                    _c("strong", [
                      _vm._v("Order #: " + _vm._s(_vm.billDetails.id))
                    ])
                  ])
                ]),
                _c("div", { staticClass: "md-layout-item md-size-55" }, [
                  _c("div", { staticClass: "bill-sub-value text-right" }, [
                    _c("strong", [
                      _vm._v(
                        "Order Type: " + _vm._s(_vm.billDetails.order_type)
                      )
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "md-layout flex-display" }, [
                _c("div", { staticClass: "md-layout-item" }, [
                  _c("div", { staticClass: "bill-sub-value text-right" }, [
                    _c("strong", [
                      _vm._v("Table: " + _vm._s(_vm.billDetails.table_no))
                    ])
                  ])
                ]),
                _c("div", { staticClass: "md-layout-item md-size-55" }, [
                  _c("div", { staticClass: "bill-sub-value text-right" }, [
                    _c("strong", [
                      _vm._v(
                        " Date: " +
                          _vm._s(
                            _vm
                              .moment(_vm.billDetails.order_date)
                              .format("DD/MM/YYYY HH:mm")
                          ) +
                          " "
                      )
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "md-layout flex-display" }, [
                _vm.billDetails.customer_name
                  ? _c("div", { staticClass: "md-layout-item" }, [
                      _c("div", { staticClass: "bill-sub-value text-right" }, [
                        _c("strong", [
                          _vm._v(
                            " Customer: " +
                              _vm._s(_vm.billDetails.customer_name) +
                              " "
                          )
                        ])
                      ])
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "md-layout-item md-size-55" }, [
                  _c("div", { staticClass: "bill-sub-value text-right" }, [
                    _c("strong", [
                      _vm._v(" Mobile: " + _vm._s(_vm.billDetails.phone) + " ")
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "md-layout flex-display" }, [
                _c("div", { staticClass: "md-layout-item" }, [
                  _c("div", { staticClass: "bill-sub-value text-right" }, [
                    _c("strong", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.billDetails.payment_type
                              .charAt(0)
                              .toUpperCase() +
                              _vm.billDetails.payment_type.slice(1)
                          ) +
                          "| " +
                          _vm._s(
                            _vm.billDetails.payment_type == "card"
                              ? "Paid"
                              : "Unpaid"
                          ) +
                          " "
                      )
                    ])
                  ])
                ]),
                _vm.billDetails.stripe_payment_id
                  ? _c("div", { staticClass: "md-layout-item md-size-55" }, [
                      _c("div", { staticClass: "bill-sub-value text-right" }, [
                        _c("strong", [
                          _vm._v(
                            " Txb Id: " +
                              _vm._s(_vm.billDetails.stripe_payment_id) +
                              " "
                          )
                        ])
                      ])
                    ])
                  : _vm._e()
              ]),
              _c("div", { staticClass: "md-layout" }, [
                _c(
                  "table",
                  { staticClass: "md-layout-item rcpt-table" },
                  [
                    _c("tr", { staticClass: "tr-dashed" }, [
                      _c("th", { attrs: { height: "30" } }, [
                        _vm._v(" Items ")
                      ]),
                      _c("th", [_vm._v("Rate")]),
                      _c("th", [_vm._v("Qty")]),
                      _c("th", [_vm._v("Amount")])
                    ]),
                    _vm._l(_vm.billDetails.cart_items, function(item, index) {
                      return _c("tr", { key: index }, [
                        _c("td", [_vm._v(_vm._s(item.name))]),
                        _c("td", [_vm._v(_vm._s(item.price.toFixed(2)))]),
                        _c("td", [_vm._v(_vm._s(item.qty))]),
                        _c("td", [
                          _vm._v(_vm._s((item.price * item.qty).toFixed(2)))
                        ])
                      ])
                    })
                  ],
                  2
                ),
                _c("table", { staticClass: "rcpt-total" }, [
                  _c("tr", [
                    _c("td"),
                    _c("td"),
                    _c("td", [_vm._v(" Sub Total: ")]),
                    _c("td", [
                      _vm._v(
                        " " + _vm._s(_vm.billDetails.amount.toFixed(2)) + " "
                      )
                    ])
                  ]),
                  _c("tr", [
                    _c("td"),
                    _c("td"),
                    _c("td", [_c("h2", [_vm._v("Total:")])]),
                    _c("td", [
                      _c("h2", [
                        _vm._v(_vm._s(_vm.billDetails.amount.toFixed(2)))
                      ])
                    ])
                  ]),
                  _vm.billDetails.payment_type == "card"
                    ? _c("tr", [
                        _c("td"),
                        _c("td"),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.billDetails.payment_type == "card"
                                ? "Card"
                                : ""
                            ) + ":"
                          )
                        ]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.billDetails.amount.toFixed(2)))
                        ])
                      ])
                    : _vm._e()
                ])
              ])
            ]),
            _c("div", { staticClass: "md-layout barcode-section" }, [
              _c(
                "div",
                { staticClass: "md-layout-item md-size-100 thanku-hd" },
                [
                  _c("i", { staticClass: "itl-text" }, [
                    _vm._v("Thank you for coming !")
                  ])
                ]
              )
            ])
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }