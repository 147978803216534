<template>
  <v-card
    id="hidden-bill"
    class="mv-2 mb-6 rpt-download"
  >
    <div>
      <div
        id="bill-detail"
        class="content-section"
      >
        <div class="md-layout rpt-location">
          <p>
            <strong>{{ name }}</strong>
          </p>
          <p>{{ address1 }}, {{ address2 }}, {{ address3 }}</p>
        </div>
        <div class="md-layout rpt-logo">
          <img :src="logo">
        </div>

        <div class="rpt-main">
          <div class="md-layout flex-display">
            <div class="md-layout-item">
              <div class="bill-sub-value text-right">
                <strong>Order #: {{ billDetails.id }}</strong>
              </div>
            </div>
            <div class="md-layout-item md-size-55">
              <div class="bill-sub-value text-right">
                <strong>Order Type: {{ billDetails.order_type }}</strong>
              </div>
              <!-- <div class="bill-sub-value text-right">
              {{ formattedDate }}
            </div> -->
            </div>
          </div>
          <div class="md-layout flex-display">
            <div class="md-layout-item">
              <div class="bill-sub-value text-right">
                <strong>Table: {{ billDetails.table_no }}</strong>
              </div>
            </div>
            <div class="md-layout-item md-size-55">
              <div class="bill-sub-value text-right">
                <strong>
                  Date:
                  {{
                    moment(billDetails.order_date).format('DD/MM/YYYY  HH:mm')
                  }}
                </strong>
              </div>
            </div>
          </div>
          <div class="md-layout flex-display">
            <div
              v-if="billDetails.customer_name"
              class="md-layout-item"
            >
              <div class="bill-sub-value text-right">
                <strong> Customer: {{ billDetails.customer_name }} </strong>
              </div>
            </div>
            <div class="md-layout-item md-size-55">
              <div class="bill-sub-value text-right">
                <strong> Mobile: {{ billDetails.phone }} </strong>
              </div>
            </div>
          </div>
          <div class="md-layout flex-display">
            <div class="md-layout-item">
              <div class="bill-sub-value text-right">
                <strong>
                  {{
                    billDetails.payment_type.charAt(0).toUpperCase() +
                      billDetails.payment_type.slice(1)
                  }}| {{ billDetails.payment_type == 'card' ? 'Paid' : 'Unpaid' }}
                </strong>
              </div>
            </div>
            <div
              v-if="billDetails.stripe_payment_id"
              class="md-layout-item md-size-55"
            >
              <div class="bill-sub-value text-right">
                <strong> Txb Id: {{ billDetails.stripe_payment_id }} </strong>
              </div>
            </div>
          </div>

          <div class="md-layout">
            <table class="md-layout-item rcpt-table">
              <tr class="tr-dashed">
                <th height="30">
                  Items
                </th>
                <th>Rate</th>
                <th>Qty</th>
                <th>Amount</th>
              </tr>
              <tr
                v-for="(item, index) in billDetails.cart_items"
                :key="index"
              >
                <td>{{ item.name }}</td>
                <td>{{ item.price.toFixed(2) }}</td>
                <td>{{ item.qty }}</td>
                <td>{{ (item.price * item.qty).toFixed(2) }}</td>
              </tr>
            </table>

            <table class="rcpt-total">
              <tr>
                <td />
                <td />
                <td>
                  Sub Total:
                </td>
                <td>
                  {{ billDetails.amount.toFixed(2) }}
                </td>
              </tr>
              <tr>
                <td />
                <td />
                <td><h2>Total:</h2></td>
                <td>
                  <h2>{{ billDetails.amount.toFixed(2) }}</h2>
                </td>
              </tr>
              <tr v-if="billDetails.payment_type == 'card'">
                <td />
                <td />

                <td>{{ billDetails.payment_type == 'card' ? 'Card' : '' }}:</td>
                <td>{{ billDetails.amount.toFixed(2) }}</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="md-layout barcode-section">
          <div class="md-layout-item md-size-100 thanku-hd">
            <i class="itl-text">Thank you for coming !</i>
          </div>
          <!-- <div class="md-layout-item md-size-100">
        <svg id="barcode"></svg>
      </div> -->
          <!-- <div class="md-layout-item md-size-100">
          <v-btn
            class="text-white"
            @click="downloadBill(123)"
          >
            Download
          </v-btn>
        </div> -->
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
// import store from '@/store'
// import { ref, watch } from '@vue/composition-api'
// import { required } from '@validations'
// import { userService } from '../api/userService'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import html2canvas from 'html2canvas'
import store from '@/store'

export default {
  props: {
    billDetails: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  setup() {
    // watch(() => props.addressProps, addressProps => {
    //   address.value = addressProps
    // })
    const {
      logo,
      name,
      address1,
      address2,
      address3,
    } = store.state.outlet.outletInfo

    const downloadBill = billNo => {
      html2canvas(document.querySelector('#bill-detail'), {
        useCORS: true,
        allowTaint: true,
        scrollY: -window.scrollY,
      })
        .then(canvas => {
          document.body.appendChild(canvas)
        })
        .then(() => {
          const canvas = document.querySelector('canvas')
          canvas.style.display = 'none'
          const image = canvas
            .toDataURL('image/png')
            .replace('image/png', 'image/octet-stream')
          const a = document.createElement('a')
          a.setAttribute('download', `${name}_${billNo}.png`)
          a.setAttribute('href', image)
          a.click()
          a.remove()
        })
    }

    return {
      downloadBill,
      logo,
      name,
      address1,
      address2,
      address3,
    }
  },
}
</script>
<style scoped>
.rpt-download {
  max-width: 400px;
  font-size: 12px !important;
  font-weight: 600;
  height: 500px;
  overflow: auto;
}
.rpt-location {
  text-align: center;
  padding-top: 15px;
}
.rpt-logo {
  padding-top: 25px;
  padding-bottom: 25px;
  margin: auto;
}
.rpt-logo img {
  display: block;
  margin: auto;
  max-width: 200px;
}
.rpt-main {
  border-top: 3px solid #000;
  border-bottom: 3px solid #000;
  padding: 20px;
}
.rcpt-table {
  width: 100%;
  text-align: left;
  border-bottom: 1pt dashed black;
}
.rcpt-table td {
  height: 25px;
}
.rcpt-total {
  width: 100%;
}
.rcpt-total td {
  height: 30px;
  min-width: 50px;
}
table {
  border-collapse: collapse;
  empty-cells: show;
}
.tr-dashed {
  border-top: 1pt dashed black;
  border-bottom: 1pt dashed black;
}
.flex-display {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.barcode-section {
  text-align: center;
  padding-top: 15px;
}
.itl-text {
  padding-top: 15px;
  padding-bottom: 25px;
  display: block;
  font-weight: 300 !important;
}
</style>
